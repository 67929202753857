import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(15.000000, 6.000000)">
        <path
          d="M47.3,42.8c0.1,2.3,0,3.8-0.6,7c-1.1,5.6-3.2,9-9.6,9H25.4V28.1h10.8c6.4,0,9,3,10.4,8.4
        C47.3,39.5,47.3,40.5,47.3,42.8z M29.2,32.2v22.1h7.3c3.9,0,6-4.3,6-11c0-6.9-2.1-11-6-11H29.2z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
