import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          transform="translate(4.000000, 1.000000)"
          d="M47.3,42.8c0.1,2.3,0,3.8-0.6,7c-1.1,5.6-3.2,9-9.6,9H25.4V28.1h10.8c6.4,0,9,3,10.4,8.4
        C47.3,39.5,47.3,40.5,47.3,42.8z M29.2,32.2v22.1h7.3c3.9,0,6-4.3,6-11c0-6.9-2.1-11-6-11H29.2z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
